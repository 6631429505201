export const setMode = 'setMode';
export const setPoint = 'setPoint';
export const setPolygon = 'setPolygon';
export const setFeature = 'setFeature';
export const setDrawMode = 'setDrawMode';
export const setTile = 'setTile';
export const setFieldId = 'setFieldId';
export const setLocation = 'setLocation';
export const setDataLayer = 'setDataLayer';
export const setToken = 'setToken';
export const setTiles = 'setTiles';
export const setAPI = 'setAPI';
export const setTileURL = 'setTileURL';
export const setDRResult = 'setDRResult';
export const setMGRSTileExceptionPolygon = 'setMGRSTileExceptionPolygon';
export const setClearButton = 'setClearButton';
