import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';

const CustomIconButton = styled(IconButton)({
  padding: 0,
  margin: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const CustomToolTip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    <CustomIconButton>{children}</CustomIconButton>
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 225,
  },
}));

export default CustomToolTip;
