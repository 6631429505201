import { API } from 'aws-amplify';

const APINAME = 'public';

export const post = async (path, payload = {}, headers = {}, api = APINAME) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.post(api, path, {
      headers: payloadHeaders,
      body: payload,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const get = async (
  path,
  queryStringParameters = {},
  headers = {},
  api = APINAME
) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.get(api, path, {
      headers: payloadHeaders,
      queryStringParameters,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};
