/* eslint-disable no-underscore-dangle */
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  Button,
  IconButton,
  Paper,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { store } from 'contexts/Map/store';

import * as turf from '@turf/turf';

import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  // LineGlobeIcon,
} from '@mui/icons-material';

import MAXZOOM from 'constants/map-constants';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
  setPolygon,
  setDrawMode,
  setMGRSTileExceptionPolygon,
  setClearButton,
} from 'contexts/Map/action';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { LineGlobeIcon, CompassIcon, SearchBar, CloseIcon } from 'icons';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import getPolygonInfoWindowPoint from 'utils/map';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZ2lyaXNocGFsbGFnYXR0aSIsImEiOiJja2h6NGVuc20wYndjMnlxaDExbzlsa3B2In0.e94ZfcoPleAA2nqjeX10ag';
// 'pk.eyJ1Ijoicm9oaXRzaGV0dHkwMCIsImEiOiJjanpmOXVoOXowOXF6M2lsdG95NmF5dWEzIn0.yuI5HCaw8Ka2-hMQM5RG4A';

let heightOfTheWindow = window.innerHeight;

window.addEventListener('resize', () => {
  heightOfTheWindow = window.innerHeight;
});

const useStyles = makeStyles({
  hoverDisplayContainer: {
    position: 'absolute',
    left: 60,
    top: 0,
    zIndex: 1,
    minHeight: 220,
    paddingLeft: 8,
    minWidth: 160,
    textAlign: 'left',
    color: '#fff',
  },
  shadowGenerator: {
    padding: 30,
    zIndex: 1,
    position: 'relative',
    top: -60,
    boxShadow: '-54px 72px 138px 62px rgba(0,0,0,0.85)',
  },
  hoverContent: {
    position: 'relative',
    zIndex: 2,
  },
  globeIconContainer: {
    position: 'absolute',
    right: 8,
    bottom: 12,
    zIndex: 1,
    height: 48,
    width: 48,
    '@media (max-width: 600px)': {
      bottom: 56,
    },
  },
  globebutton: {
    width: 48,
    height: 48,
  },
  checkboxContainer: {
    position: 'absolute',
    left: 8,
    bottom: 38,
    zIndex: 1000,
    color: 'black',
    backgroundColor: 'white',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px',
    '& .MuiCheckbox-root': {
      color: '#06b075', // DigiFarm green
      '&.Mui-checked': {
        color: '#06b075', // DigiFarm green
      },
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'white', // Color of the check icon
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
  iconRoot: {
    fill: 'none',
  },
  clearButtonContainer: {
    position: 'absolute',
    right: 60,
    bottom: 12,
    // display: 'flex',
    // width: '100%',
    // justifyContent: 'center',
  },
  clearButton: {
    backgroundColor: '#1D2023',
    fontSize: 14,
  },
  drawerToggleContainer: {
    position: 'absolute',
    right: 8,
    top: '40vh',
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  collapseDrawerButton: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 8,
    padding: 8,
    '&:hover': {
      color: '#999',
    },
  },
  collapseDrawerButtonMobile: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 8,
    padding: 8,
  },
  drawerToggleMobileOpen: {
    position: 'absolute',
    right: 68,
    top: heightOfTheWindow - 340,
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (min-width: 600px)': {
      display: 'none',
    },
  },
  drawerToggleMobileClosed: {
    position: 'absolute',
    right: 68,
    bottom: 64,
    backgroundColor: '#fff',
    borderRadius: 8,
    '@media (min-width: 600px)': {
      display: 'none',
    },
  },
  searchBarContainer: {
    position: 'absolute',
    bottom: 172,
    right: 8,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
  },
  searchFormContainer: {
    overflow: 'hidden',
    transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
    width: 0,
    height: 0,
    display: 'flex',
    alignItems: 'center',
  },
  searchFormContainerOpen: {
    width: '500px', // Adjust the width as needed
    height: '80px',
  },
  searchForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
    padding: '10px 0',
  },
  formControl: {
    marginRight: 10,
    flex: 1,
    height: '40px',
    bottom: '0.5em',
    backgroundColor: '#fff',
    color: 'primary',
  },
});

const DrawContext = createContext();
const MapContext = createContext();

export const useDrawContext = () => useContext(DrawContext);
export const useMapContext = () => useContext(MapContext);

export const DrawProvider = ({ children }) => {
  const [draw, setDraw] = useState(null); // Initialize draw state

  return (
    <DrawContext.Provider value={{ draw, setDraw }}>
      {children}
    </DrawContext.Provider>
  );
};

export const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null);

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};

const Map = (props) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const {
    state: {
      dataLayer,
      mgrsExceptionPolygon,
      MIN_AOI_AREA_IN_HECTARE,
      MAX_AOI_AREA_IN_HECTARE,
      showClearButton,
      tileURL,
      tileUrlCounter,
      viewBounds,
      tileBounds,
    },
    dispatch,
  } = useContext(store);

  const classes = useStyles();

  const { zoom, setZoom, toggleRightDrawer, isRightDrawerOpen } = props;

  const { draw, setDraw } = useDrawContext();

  const { map, setMap } = useMapContext();

  const [mapDrawMode, setMapDrawMode] = useState(dataLayer.drawMode);

  const [basemap, setBasemap] = useState('satellite-streets-v11');

  const [mgrsCheckbox, setMGRSCheckbox] = useState(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [searchValues, setSearchValues] = useState({
    longitude: '',
    latitude: '',
  });

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchValues({ ...searchValues, [name]: value });
  };

  const handleCoordinateSubmit = () => {
    // const long = parseFloat(e.target.longitude.value);
    // const lati = parseFloat(e.target.latitude.value);

    const long = parseFloat(searchValues.longitude);
    const lati = parseFloat(searchValues.latitude);

    if (!Number.isNaN(long) && !Number.isNaN(lati) && map) {
      map.flyTo({
        center: [long, lati],
        zoom: 14, // Adjust zoom level as needed
      });

      if (map.getSource('point')) {
        map.removeLayer('point');
        map.removeSource('point');
      }

      if (map.getSource('point')) {
        map.getSource('point').setData({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [long, lati],
          },
        });
      } else {
        map.addSource('point', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [long, lati],
            },
          },
        });

        map.addLayer({
          id: 'point',
          type: 'circle',
          source: 'point',
          paint: {
            'circle-radius': 14,
            'circle-color': '#088',
          },
        });
      }

      dispatch({
        type: setClearButton,
        payload: {
          showClearButton: true,
        },
      });
    }
  };

  const handleSearchSubmit = () => {
    // e.preventDefault();
    if (searchValues.longitude && searchValues.latitude) {
      handleCoordinateSubmit();
      // setIsSearchBarVisible(false);
      // setSearchValues({
      //   longitude: '',
      //   latitude: '',
      // });
    }
  };

  const handleSearchClose = () => {
    setSearchValues({
      longitude: '',
      latitude: '',
    });
  };

  const handleCheckboxChange = (event) => {
    setMGRSCheckbox(event.target.checked);

    if (event.target.checked && map) {
      if (!map.getSource('mgrs_tiles')) {
        map.addSource('mgrs_tiles', {
          type: 'vector',
          tiles: [
            'https://vectors.digifarm.io/v1/vectors/test/test/{z}/{x}/{y}.mvt?table=_mgrs&showProps=true&limit=2000',
          ],
        });

        map.addLayer({
          id: 'mgrs_polygons',
          type: 'fill',
          source: 'mgrs_tiles',
          'source-layer': 'test',
          paint: {
            'fill-color': 'rgba(225, 225, 225, 0.15)',
            'fill-opacity': [
              'step',
              ['zoom'],
              1, // fill opacity at zoom levels less than 9
              9, // starting at zoom level 9
              0, // fill opacity is 0
            ],
          },
        });

        map.addLayer({
          id: 'mgrs_lines',
          type: 'line',
          source: 'mgrs_tiles',
          'source-layer': 'test',
          paint: {
            'line-color': '#fff', // white line color
            'line-width': [
              'step',
              ['zoom'],
              0, // line width at zoom levels less than 9
              9, // starting at zoom level 9
              1, // line width is 1
            ],
          },
        });
        map.addLayer({
          id: 'mgrs_tiles_labels',
          type: 'symbol',
          source: 'mgrs_tiles',
          'source-layer': 'test', // replace with your source layer name
          layout: {
            'text-field': ['get', 'name'], // access the 'name' property from your data
            'text-size': [
              'step',
              ['zoom'],
              7,
              4,
              10, // text size at zoom levels less than 5
              5, // starting at zoom level 5
              13, // text size is 13
              9, // starting at zoom level 9
              17, // text size is 17
            ],
            'text-justify': 'center', // center the text
            'text-anchor': 'center', // anchor the text in the center
            'text-font': ['Open Sans Bold'], // set the font to bold
          },
          paint: {
            'text-color': '#fff', // set the text color to white
          },
        });
      }
    } else if (!event.target.checked && map) {
      if (map.getSource('mgrs_tiles')) {
        map.removeLayer('mgrs_tiles_labels');
        map.removeLayer('mgrs_polygons');
        map.removeLayer('mgrs_lines');
        map.removeSource('mgrs_tiles');
      }
    }
  };

  useEffect(() => {
    const localMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: [11.1804734, 60.746268],
      zoom,
      hash: true,
      maxZoom: MAXZOOM,
    });

    const Draw = new MapboxDraw({
      displayControlsDefault: false,
      modes: {
        // draw_single_point: SinglePointMode,
        draw_rectangle: DrawRectangle,
        ...MapboxDraw.modes,
      },
    });

    localMap.addControl(Draw);
    Draw.changeMode(dataLayer.drawMode);

    setDraw(Draw);

    localMap.on('load', () => {
      localMap.on('zoomend', () => {
        setZoom(localMap.getZoom());
      });

      localMap.on('draw.create', (e) => {
        if (mapDrawMode === 'draw_rectangle') {
          dispatch({
            type: setPolygon,
            payload: {
              polygon: e.features[0].geometry.coordinates,
            },
          });

          dispatch({
            type: setClearButton,
            payload: {
              showClearButton: true,
            },
          });

          const topPoint = getPolygonInfoWindowPoint(e.features[0]);

          const area = turf.area(e.features[0]) / 10000;

          let warningCk = false;

          if (
            area < MIN_AOI_AREA_IN_HECTARE ||
            area > MAX_AOI_AREA_IN_HECTARE
          ) {
            warningCk = true;
            const bottomPoint = getPolygonInfoWindowPoint(
              e.features[0],
              'bottom'
            );

            new mapboxgl.Popup({
              closeOnClick: false,
              closeButton: false,
              anchor: 'top',
              maxWidth: '500px',
              className: 'bottom-popup',
            })
              .setLngLat(bottomPoint)
              .setHTML(
                `ⓘ Please select an AOI between ${MIN_AOI_AREA_IN_HECTARE.toLocaleString()}ha and ${MAX_AOI_AREA_IN_HECTARE.toLocaleString()}ha.`
              )
              .addTo(localMap);
          }

          new mapboxgl.Popup({
            closeOnClick: false,
            closeButton: false,
            className: 'top-popup',
            maxWidth: '150px',
          })
            .setLngLat(topPoint)
            .setHTML(
              `<p class="${
                warningCk ? 'warning' : ''
              }"><b>Area:</b> ${area.toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })} ha</p>`
            )
            .addTo(localMap);
        }
      });
    });

    localMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    localMap.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        types: 'country,region,district,place,locality,postcode',
        collapsed: true,
        marker: {
          color: '#01B075',
        },
      })
    );

    setMap(localMap);
    mapRef.current = localMap;

    // Clean up on unmount
    return () => localMap.remove();
  }, []);

  const polyMask = (mask, bounds) => {
    // const bboxPoly = bboxPolygon(bounds);
    // console.log({ mask, bounds });
    const maskPoly = turf.bboxPolygon(mask);
    const boundsPolyg = bounds;
    // console.log(maskPoly);
    // console.log(boundsPolyg);
    const dif = turf.difference(boundsPolyg, maskPoly);
    // console.log(dif, 'dif');
    return dif;
  };

  useEffect(() => {
    if (map && map.getSource('tileURL')) {
      // console.log('removing tileURL');
      map.removeLayer('tileURL');
      map.removeSource('tileURL');
    }

    if (tileURL && map && dataLayer.type === 'partialDR') {
      if (map.getLayer('tileURL')) {
        map.removeLayer('tileURL');
        map.removeSource('tileURL');
      }

      if (map.getLayer('tilemasklayer')) {
        map.removeLayer('tilemasklayer');
        map.removeSource('tilemask');
      }
      // console.log('adding tileURL, Partial', dataLayer, tileBounds);
      map.addSource('tileURL', {
        type: 'raster',
        tiles: [tileURL],
        tileSize: 256,
      });

      map.addLayer({
        id: 'tileURL',
        source: 'tileURL',
        type: 'raster',
        minzoom: 0,
        maxzoom: MAXZOOM,
        layout: { visibility: 'visible' },
      });

      map.addSource('tilemask', {
        type: 'geojson',
        data: polyMask(viewBounds, tileBounds),
      });

      map.addLayer({
        id: 'tilemasklayer',
        type: 'fill',
        source: 'tilemask',
        paint: {
          'fill-color': 'rgba(46, 204, 113,0.2)',
        },
      });
    }

    if (map && map.getSource('bbox-image')) {
      map.removeLayer('bbox-image');
      map.removeSource('bbox-image');
    }
  }, [tileURL, tileUrlCounter]);

  const toggleBaseLayer = () => {
    if (basemap === 'satellite-streets-v11') {
      map.setStyle('mapbox://styles/mapbox/light-v11');
      setBasemap('light-v11');
    } else {
      map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
      setBasemap('satellite-streets-v11');
    }
  };

  const removeMGRSExpectionLayer = () => {
    if (map && map.getSource('mgrsException-feature')) {
      map.removeLayer('mgrsException-layer');
      map.removeLayer('mgrsException-line');
      map.removeSource('mgrsException-feature');
    }
  };

  useEffect(() => {
    if (map && mgrsExceptionPolygon) {
      removeMGRSExpectionLayer();

      map.addSource('mgrsException-feature', {
        type: 'geojson',
        data: mgrsExceptionPolygon,
      });

      map.addLayer({
        id: 'mgrsException-layer',
        type: 'fill',
        source: 'mgrsException-feature',
        paint: {
          'fill-color': 'rgba(255, 255, 255, 0.2)',
        },
      });

      map.addLayer({
        id: 'mgrsException-line',
        type: 'line',
        source: 'mgrsException-feature',
        paint: {
          'line-width': 2,
          'line-color': '#fff',
        },
      });
    }
  }, [mgrsExceptionPolygon]);

  return (
    <div className="map-and-controls-container">
      <div className="map-container" ref={mapContainerRef} />
      <div className={classes.searchBarContainer}>
        <div
          className={`${classes.searchFormContainer} ${
            isSearchBarVisible ? classes.searchFormContainerOpen : ''
          }`}
        >
          {isSearchBarVisible && (
            <>
              {(searchValues.longitude || searchValues.latitude) && (
                <IconButton title="Coordinate Search Close">
                  <CloseIcon
                    style={{
                      fontSize: '25px',
                      marginTop: '6px',
                      marginLeft: '6px',
                      marginBottom: '6px',
                    }}
                    onClick={() => {
                      handleSearchClose();
                    }}
                  />
                </IconButton>
              )}

              <form className={classes.searchForm}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="latitude">Latitude</InputLabel>
                  <OutlinedInput
                    id="latitude"
                    name="latitude"
                    value={searchValues.latitude}
                    onChange={handleSearchChange}
                    label="Latitude"
                    required
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="longitude">Longitude</InputLabel>
                  <OutlinedInput
                    id="longitude"
                    name="longitude"
                    value={searchValues.longitude}
                    onChange={handleSearchChange}
                    label="Longitude"
                    required
                  />
                </FormControl>
              </form>
            </>
          )}
        </div>
        <IconButton
          onClick={() => {
            if (searchValues.longitude && searchValues.latitude) {
              handleSearchSubmit();
            }
            setIsSearchBarVisible(!isSearchBarVisible);
          }}
          style={{ width: '50px', height: '50px' }}
          title="Coordinate Search"
        >
          {isSearchBarVisible ? (
            <SearchBar
              classes={{ root: classes.iconRoot }}
              style={{ fontSize: '30px', marginTop: '10px' }}
            />
          ) : (
            <CompassIcon
              classes={{ root: classes.iconRoot }}
              style={{ fontSize: '30px' }}
            />
          )}
        </IconButton>
      </div>
      <div className={classes.globeIconContainer}>
        <Paper>
          <IconButton onClick={toggleBaseLayer} className={classes.globebutton}>
            <LineGlobeIcon classes={{ root: classes.iconRoot }} />
          </IconButton>
        </Paper>
      </div>
      <div className={classes.checkboxContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={mgrsCheckbox}
              onChange={handleCheckboxChange}
              color="default"
            />
          }
          label="MGRS"
          labelPlacement="end"
        />
      </div>
      <div className={classes.clearButtonContainer}>
        {showClearButton && (
          <Button
            variant="contained"
            color="primary"
            className={classes.clearButton}
            onClick={() => {
              draw.deleteAll();

              dispatch({
                type: setDrawMode,
                payload: {
                  drawMode: 'draw_rectangle',
                },
              });

              removeMGRSExpectionLayer();

              dispatch({
                type: setMGRSTileExceptionPolygon,
                payload: {
                  mgrsExceptionPolygon: null,
                },
              });

              if (map.getSource('bbox-area-mask')) {
                map.removeLayer('bbox-area-mask');
                map.removeSource('bbox-area-mask');
              }

              if (map.getSource('point')) {
                map.removeLayer('point');
                map.removeSource('point');
              }

              if (map.getSource('tilemask')) {
                map.removeLayer('tilemasklayer');
                map.removeSource('tilemask');
              }

              if (map.getSource('tileURL')) {
                map.removeLayer('tileURL');
                map.removeSource('tileURL');
              }

              draw.changeMode('draw_rectangle');

              if (map._popups) {
                // eslint-disable-next-line prefer-destructuring
                const length = map._popups.length;

                for (let index = 0; index < length; index += 1) {
                  map._popups[0].remove();
                }
              }

              dispatch({
                type: setClearButton,
                payload: {
                  showClearButton: false,
                },
              });
            }}
          >
            Clear
          </Button>
        )}
      </div>

      <div className={classes.drawerToggleContainer}>
        <IconButton
          onClick={toggleRightDrawer}
          className={classes.collapseDrawerButton}
        >
          {isRightDrawerOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <div
        className={
          isRightDrawerOpen
            ? classes.drawerToggleMobileOpen
            : classes.drawerToggleMobileClosed
        }
      >
        <IconButton
          onClick={toggleRightDrawer}
          className={classes.collapseDrawerButtonMobile}
        >
          {isRightDrawerOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      </div>
    </div>
  );
};

export default Map;
