import * as turf from '@turf/turf';

// Function to compute top-center or bottom-center of a polygon
const getPolygonInfoWindowPoint = (polygon, position = 'top') => {
  const allCoords = turf.coordAll(polygon); // Get all coordinates

  // Get highest (max Y) or lowest (min Y) point
  const extremeY =
    position === 'top'
      ? Math.max(...allCoords.map((coord) => coord[1])) // Highest Y for top
      : Math.min(...allCoords.map((coord) => coord[1])); // Lowest Y for bottom

  // Get all points that match this Y value
  const extremePoints = allCoords.filter((coord) => coord[1] === extremeY);

  // Sort by X-axis to find middle point
  extremePoints.sort((a, b) => a[0] - b[0]);

  // True center: Average of leftmost & rightmost X at extremeY
  const midX =
    (extremePoints[0][0] + extremePoints[extremePoints.length - 1][0]) / 2;

  return [midX, extremeY];
};

export default getPolygonInfoWindowPoint;
