/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */

import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@mui/material';
import mapboxgl from 'mapbox-gl';

import React, { useState, useEffect, useRef, useContext } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Collapse from '@mui/material/Collapse';
import { valid } from 'geojson-validation';
import lodash from 'lodash';

import { store } from 'contexts/Map/store';

import { useCreditContext } from 'contexts/Credit';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import {
  setPolygon,
  setTileURL,
  setDrawMode,
  setClearButton,
  setMGRSTileExceptionPolygon,
} from 'contexts/Map/action';
// import { MuiPickersUtilsProvider } from '@mui/pickers';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import copyToClipboard from 'helpers/copyToClipboard';
import * as Bluebird from 'bluebird';
// import { LocalizationProvider, DatePicker } from '@mui/lab';
import ProgressModal from 'components/ProgressModal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Toast from 'components/Toast';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// import { LocalizationProvider, DatePicker } from '@mui/lab/';
// import DateFnsUtils from '@date-io/date-fns';
import {
  getSentinelCover,
  createSubscription as createSubscriptionAPI,
  createSubscriptionImagery as createSubscriptionImageryAPI,
} from 'api/partialdr/subscriptions';
import * as turf from '@turf/turf';
import InputAdornment from '@mui/material/InputAdornment';
import { RotateIcon } from 'utils/icons';
import getPolygonInfoWindowPoint from 'utils/map';
import { useDrawContext, useMapContext } from './index';
import { locationObj } from '../../helpers/mapUtils';

// import { DOMParser } from 'xmldom';
// import tj from '@mapbox/togeojson';
import InputField from './InputField';
// import Disclaimer from './Disclaimer';
import SentinelAvailableImageriesTable from './SentinelAvailableImageriesTable';
import CloudCoverageSlider from './CloudCoverageSlider';
import GeoJSONInstructionModal from '../Modal/geojsonInstructions';
import AoiChangeConfirmationModal from '../Modal/aoiChangeConfirmation';

// import enGB from 'date-fns/locale/en-GB';

const useStyles = makeStyles({
  info: {
    padding: '24px 20px',
    boxSizing: 'border-box',
    color: '#949EA7',
    backgroundColor: '#fff',
    marginBottom: 16,
    '& h3': {
      lineHeight: '24px',
      marginBottom: 16,
      color: '#1D2023',
    },
    '& h4': {
      color: '#1D2023',
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
      margin: 0,
    },
    '& h6': {
      color: '#1D2023',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
      margin: 0,
    },
  },
  datePicker: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#F8F9FA',
    },
  },
  iconRoot: {
    fill: 'none',
  },
  mx8: {
    marginTop: 16,
    marginBottom: 16,
  },
  flex: {
    display: 'flex',
  },
  flex1: {
    flex: 1,
    wordWrap: 'anywhere',
  },
  rightContainer: {
    flex: 1,
    wordWrap: 'anywhere',
    color: '#1D2023',
    '& h6': {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: 'flex',
    marginBottom: 16,
    fontWeight: 700,
    maxWidth: '100%',
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: '20px',
  },
  inputRoot: {
    borderRadius: 4,
    backgroundColor: '#F8F9FA',
  },
  generateResp: {
    marginTop: 16,
    textTransform: 'capitalize',
  },
  url: {
    color: '#EA891D',
  },
  copyButton: {
    marginRight: 4,
    textTransform: 'capitalize',
    borderRadius: 4,
    marginTop: 8,
    '@media (max-width: 600px)': {
      position: 'static',
      marginBottom: 8,
    },
  },
  textFields: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 12,
  },

  parameters: {
    marginTop: '24px',
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(68px, 1fr))',
    columnGap: '16px',
    rowGap: '16px',
  },
  drImageThumbnail: {
    width: '100%',
    borderRadius: 4,
    border: '1px solid #EEEFF1',
  },
  rasterUrlContainer: {
    position: 'relative',
    display: 'flex',
    marginTop: 16,
  },
  rasterUrl: {
    color: '#1D2023',
    fontWeight: 600,
    maxWidth: '100%',
    wordWrap: 'anywhere',
    marginRight: 8,
  },
  rasterUrlLabel: {
    fontSize: 12,
    marginBottom: 8,
  },
  inputRootText: {
    borderRadius: 4,
    backgroundColor: '#F8F9FA',
    border: '1px solid #BFBFC0',
    cursor: 'auto',
  },

  shrinkLabel: {
    fontSize: 14,
  },

  uploadGeojson: {
    cursor: 'pointer',
  },
});

function countPolygonsInGeoJSON(geojson) {
  let features;
  if (geojson.type === 'FeatureCollection') {
    features = geojson.features;
  } else if (geojson.type === 'Feature') {
    features = [geojson];
  } else if (geojson.type === 'Polygon' || geojson.type === 'MultiPolygon') {
    features = { type: 'Feature', geometry: [geojson] };
  } else {
    console.error(
      'Invalid GeoJSON type. Expected "Feature" or "FeatureCollection". Check ⓘ button.'
    );
    return {
      result: false,
      msg: 'Invalid GeoJSON type. Expected "Feature" or "FeatureCollection". Check ⓘ button.',
    };
  }

  const geom = turf.getType(geojson);

  if (features.length !== 1) {
    return {
      result: false,
      msg: 'More than one feature type. Check ⓘ button.',
    };
  }

  if (
    features[0].geometry.type !== 'Polygon' &&
    features[0].geometry.type !== 'MultiPolygon'
  ) {
    return {
      result: false,
      msg: `You have selected ${features[0].geometry.type} Feature. This type is not supported. Please select a single polygon or multi-polygon coordinate. Check ⓘ button.`,
    };
  }

  const coordinatesLength = features[0].geometry.coordinates.length;

  if (coordinatesLength !== 1) {
    return {
      result: false,
      msg: 'Multiple coordinates found. We have only enabled Polygon and MultiPolygon single coordinate geometery functionality now. Check ⓘ button.',
    };
  }

  // console.log('Enter -1', features.length);

  // Check for the presence of other geometry types
  const otherTypes = lodash.some(
    features,
    (feature) =>
      feature.geometry.type !== 'Polygon' &&
      feature.geometry.type !== 'MultiPolygon'
  );

  if (otherTypes) {
    return {
      result: false,
      msg: 'Feature type not supported. Please select a polygon or multi-polygon with single geometry coordinate functionality. Check ⓘ button.',
    };
  }

  // Count occurrences of 'Polygon' type
  const polygonCount = lodash.countBy(features, 'geometry.type').Polygon;
  const multiPolygonCount = lodash.countBy(
    features,
    'geometry.type'
  ).MultiPolygon;

  if (polygonCount !== undefined) {
    return {
      result: true,
      msg: polygonCount,
    };
  }
  if (multiPolygonCount !== undefined) {
    return {
      result: true,
      msg: multiPolygonCount,
    };
  }
  return {
    result: false,
    msg: 'Uploaded Polygon is not supported. Check ⓘ button.',
  };
}

function calculateGeojsonSize(geojson) {
  const areaInSquareMeters = turf.area(geojson);
  return areaInSquareMeters / 10000; // Convert to hectares
}

function calculateBboxSize(bbox) {
  const bboxPolygon = turf.bboxPolygon(bbox);
  const areaInSquareMeters = turf.area(bboxPolygon);
  return areaInSquareMeters / 10000; // Convert to hectares
}

const PartialDRBboxDrawer = (props) => {
  const {
    creditState: { availableCredits, isLoading: creditApiLoadingState },
  } = useCreditContext();

  const classes = useStyles();
  const sentinelViewRef = useRef(null);
  const history = useHistory();
  const { draw } = useDrawContext();
  const { map } = useMapContext();
  const {
    state: {
      polygon,
      MIN_AOI_AREA_IN_HECTARE,
      MAX_AOI_AREA_IN_HECTARE,
      dataLayer,
    },
    dispatch,
  } = useContext(store);

  const [startDate, setStartDate] = React.useState(new Date('2023-01-01'));
  const [endDate, setEndDate] = React.useState(new Date());
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [aoiSelection, setAoiSelection] = useState('bbox'); // Add this state

  const [loading, setLoading] = useState(false);
  // Need to set it from locationObject[region].mgrs
  const [name, setName] = useState('');
  const [bboxSize, setBboxSize] = useState(0);
  const [geojsonSize, setGeojsonSize] = useState(0);

  const [bBoxPoints, setBBoxPoints] = useState([]);
  const [aoiGeojsonData, setAoiGeojsonData] = useState(null);

  const [requestUrl, setRequestUrl] = useState('');
  const [imageBlobUrl, setImageBlobUrl] = useState('');
  const [sentinelImagery, setSentinelImagery] = useState([]);
  const [selectedImagery, setSelectedImagery] = useState([]);
  const [modalProgressMessage, setModalProgressMessage] = useState('');
  const [modalProgressValue, setModalProgressValue] = useState(0);
  const [getBBoxFromPolyCheck, setGetBBoxFromPolyCheck] = useState(false);
  const [modalProgressOpen, setModalProgressOpen] = useState(false);
  const [toastAlertOpts, setToastAlertOpts] = useState({
    show: false,
    duration: 5000,
    severity: 'success',
    message: '',
  });
  const [cloudCoveragePercentage, setCloudCoveragePercentage] = useState(20);

  const [alertBox, setAlertBox] = useState({
    message: '',
    variant: 'warning',
    open: false,
  });

  const [loadingPDRData, setLoadingPDRData] = useState(false);
  const [showAoiChangeConfirmation, setShowAoiChangeConfirmation] =
    useState(false);

  const aoiValue = useRef({ current: 'bbox', previous: 'bbox' });
  const mapTopPopup = useRef(null);
  const mapBottomPopup = useRef(null);

  const clearMapboxPopup = () => {
    if (map._popups) {
      const length = map._popups.length;

      for (let index = 0; index < length; index += 1) {
        map._popups[0].remove();
      }
    }
  };

  const switchMapDraw = () => {
    if (map.getSource('bbox-area-mask')) {
      map.removeLayer('bbox-area-mask');
      map.removeSource('bbox-area-mask');
    }

    if (map.getSource('tilemask')) {
      map.removeLayer('tilemasklayer');
      map.removeSource('tilemask');
    }

    if (map.getSource('tileURL')) {
      map.removeLayer('tileURL');
      map.removeSource('tileURL');
    }

    if (aoiValue.current.current === 'bbox' && map && draw) {
      if (draw.getMode() !== 'draw_rectangle') {
        draw.changeMode('draw_rectangle');
        dispatch({
          type: setDrawMode,
          payload: {
            drawMode: 'draw_rectangle',
          },
        });
      }
    } else if (aoiValue.current.current === 'vector' && draw) {
      if (draw) {
        draw.changeMode('simple_select');
        dispatch({
          type: setDrawMode,
          payload: {
            drawMode: 'simple_select', // Set to a non-drawing mode
          },
        });
      }
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleOpenModalGeojsonInstructions = () => {
    setShowInstructionModal(true);
  };

  const handleAoiChange = (event) => {
    const selectedAoi = event.target.value;
    aoiValue.current.previous = aoiValue.current.current;
    aoiValue.current.current = selectedAoi;

    if (bBoxPoints.length > 0) {
      setShowAoiChangeConfirmation(true);
    } else {
      setAoiSelection(selectedAoi);
      switchMapDraw();
      setGetBBoxFromPolyCheck(false);
    }
  };

  const handleAoiChangeConfirmation = () => {
    setShowAoiChangeConfirmation(false); // Close the confirmation modal
    // Set the new AOI
    setBBoxPoints([]);
    setGetBBoxFromPolyCheck(false);
    setBboxSize(0);
    setGeojsonSize(0);
    setSentinelImagery([]);
    setAoiGeojsonData(null);
    setAoiSelection(aoiValue.current.current);
    setAlertBox({
      open: false,
    });

    draw.deleteAll();
    if (map.getSource('point')) {
      map.removeLayer('point');
      map.removeSource('point');
    }
    draw.changeMode('draw_rectangle');
    dispatch({
      type: setDrawMode,
      payload: {
        drawMode: 'draw_rectangle',
      },
    });

    clearMapboxPopup();

    dispatch({
      type: setMGRSTileExceptionPolygon,
      payload: {
        mgrsExceptionPolygon: null,
      },
    });

    switchMapDraw();
  };

  const handleCancelAoiChange = () => {
    setShowAoiChangeConfirmation(false); // Close the confirmation modal
    setAoiSelection(aoiValue.current.previous); // Revert to the previous selection
    // Also revert the current ref value to the previous one to keep them in sync
    aoiValue.current.current = aoiValue.current.previous;
  };

  const findGeometry = (jsonObj) => {
    // Base case: If the input is not an object, return null
    if (typeof jsonObj !== 'object' || !jsonObj) {
      return null;
    }

    // Check if the current object has the "geometry" key
    if ('geometry' in jsonObj) {
      return jsonObj.geometry;
    }

    // Recursively search through nested objects
    return Object.keys(jsonObj).reduce((acc, key) => {
      // If the geometry is found, return it
      if (acc !== null) {
        return acc;
      }
      // Recursively search if the current value is an object
      return findGeometry(jsonObj[key]);
    }, null);
  };

  const calculateCenter = (bbox) => {
    const [xmin, ymin, xmax, ymax] = bbox;

    const xCenter = (xmin + xmax) / 2;
    const yCenter = (ymin + ymax) / 2;

    return [xCenter, yCenter];
  };

  function flytoLocation(aoiFeatRes, bboxArr) {
    const parsedBboxPoints = bboxArr.map((point) => parseFloat(point));
    const defaultLocation = Object.values(locationObj[dataLayer.type])[0];

    const bboxPolygon = turf.bboxPolygon(bboxArr);

    if (parsedBboxPoints) {
      map.flyTo({
        center: calculateCenter(parsedBboxPoints),
        zoom: 13.7,
        essential: true,
      });
    } else {
      map.flyTo({
        center: defaultLocation.center,
        zoom: defaultLocation.zoom ? defaultLocation.zoom : 14,
        essential: true,
      });
    }

    if (aoiFeatRes || aoiValue.current.current === 'bbox') {
      if (map.getSource('bbox-area-mask')) {
        map.removeLayer('bbox-area-mask');
        map.removeSource('bbox-area-mask');
      }

      // Add a new layer to the map for the bbox
      let aoiRes = aoiFeatRes;
      if (aoiFeatRes === null && aoiValue.current.current === 'bbox') {
        aoiRes = bboxPolygon;
      }

      map.addSource('bbox-area-mask', {
        type: 'geojson',
        data: getBBoxFromPolyCheck ? bboxPolygon : aoiRes,
      });

      map.addLayer({
        id: 'bbox-area-mask',
        type: 'fill',
        source: 'bbox-area-mask',
        layout: {},
        paint: {
          'fill-color': '#088', // Set the fill color
          'fill-opacity': 0.5, // Set the fill opacity
        },
      });
    }
  }

  const resetPreviousData = () => {
    setSentinelImagery([]);
    setAlertBox({
      open: false,
    });

    setLoadingPDRData(false);
  };

  const handleFileUpload = (acceptedFiles) => {
    setLoading(true); // Start loading
    setAlertBox({
      open: false,
    });

    const file = acceptedFiles[0]; // Get the first file from the array
    const reader = new FileReader();
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
      setLoading(false); // End loading
      setAlertBox({
        variant: 'error',
        open: true,
        message: 'An error occurred while reading the file.',
      });
    };
    reader.onload = (readEvent) => {
      const arrayBuffer = readEvent.target.result;
      const textDecoder = new TextDecoder('utf-8');
      const text = textDecoder.decode(arrayBuffer);
      try {
        const geojson = JSON.parse(text);
        // const featureCollectionObj = convertToFeatureCollection(geojson);
        const featureCollectionObj = geojson;
        // console.log(featureCollectionObj);

        let isValidGeoJSON;
        try {
          isValidGeoJSON = valid(featureCollectionObj);
        } catch (validationError) {
          console.error('GeoJSON validation error:', validationError);
          setLoading(false); // End loading

          setAlertBox({
            message:
              'An error occurred while validating the GeoJSON file. Check ⓘ button.',
            variant: 'error',
            open: true,
          });
        }

        if (!isValidGeoJSON) {
          setLoading(false); // End loading
          setAlertBox({
            message:
              'The uploaded file is not a valid GeoJSON. Check ⓘ button.',

            variant: 'error',
            open: true,
          });
          return;
        }

        const polygonCount = countPolygonsInGeoJSON(geojson);

        if (polygonCount.result === false) {
          setLoading(false);
          setAlertBox({
            message: polygonCount.msg,
            variant: 'error',
            open: true,
          });
          return;
        }

        if (polygonCount.result === true && polygonCount.msg !== 1) {
          setLoading(false);
          setAlertBox({
            message:
              'Uploaded geojson has more than one polygon. Check ⓘ button.',
            variant: 'error',
            open: true,
          });
          return;
        }

        const [minLon, minLat, maxLon, maxLat] =
          turf.bbox(featureCollectionObj);

        if (
          minLon === Infinity ||
          minLat === Infinity ||
          maxLon === -Infinity ||
          maxLat === -Infinity
        ) {
          // setLoading(false); // End loading
          setLoading(false); // End loading

          setAlertBox({
            message:
              'Error in JSON coordinates. We support polygon and multipolygon single geometry coordinates as of now. Check ⓘ button.',
            variant: 'error',
            open: true,
          });
          return;
        }

        setToastAlertOpts({
          show: true,
          severity: 'success',
          message:
            'File uploaded successfully. Click on Generate Response to get the S2 data.',
          duration: 5000,
        });

        const bboxArr = [minLon, minLat, maxLon, maxLat];
        const bboxAreaHec = calculateBboxSize(bboxArr);
        const geojsonAreaHec = calculateGeojsonSize(featureCollectionObj);

        setBBoxPoints(bboxArr);
        setGeojsonSize(geojsonAreaHec);
        setBboxSize(bboxAreaHec);

        const aoiFeatRes = findGeometry(featureCollectionObj) || null;

        setAoiGeojsonData(aoiFeatRes);

        const bboxPolygon = turf.bboxPolygon(bboxArr);

        const topPoint = getPolygonInfoWindowPoint(bboxPolygon);

        let warningCk = false;

        if (
          bboxAreaHec < MIN_AOI_AREA_IN_HECTARE ||
          bboxAreaHec > MAX_AOI_AREA_IN_HECTARE
        ) {
          warningCk = true;

          const bottomPoint = getPolygonInfoWindowPoint(bboxPolygon, 'bottom');

          new mapboxgl.Popup({
            closeOnClick: false,
            closeButton: false,
            anchor: 'top',
            maxWidth: '500px',
            className: 'bottom-popup',
          })
            .setLngLat(bottomPoint)
            .setHTML(
              `ⓘ Please select an AOI with a bounding box area between ${MIN_AOI_AREA_IN_HECTARE.toLocaleString()}ha and ${MAX_AOI_AREA_IN_HECTARE.toLocaleString()}ha.`
            )
            .addTo(map);
        }

        mapTopPopup.current = new mapboxgl.Popup({
          closeOnClick: false,
          closeButton: false,
          className: 'top-popup',
          maxWidth: '150px',
        })
          .setLngLat(topPoint)
          .setHTML(
            `<p class="${warningCk ? 'warning' : ''}"><b>Area:</b> ${
              getBBoxFromPolyCheck
                ? bboxAreaHec.toFixed(2).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })
                : geojsonAreaHec.toFixed(2).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })
            } ha</p>`
          )
          .addTo(map);

        flytoLocation(aoiFeatRes, bboxArr);

        setAlertBox({
          open: false,
        });

        dispatch({
          type: setClearButton,
          payload: {
            showClearButton: true,
          },
        });

        setLoading(false); // End loading
      } catch (error) {
        console.error('GeoJson parsing error:', error);
        setLoading(false); // End loading

        setAlertBox({
          message: 'Wrong Geojson format',
          variant: 'error',
          open: true,
        });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
  });

  const renderTCI = (row, bbox) => {
    dispatch({
      type: setTileURL,
      payload: {
        tileURL: row.TCI_TileURL,
        viewBounds: bbox,
        tileBounds: row.geometry,
        tileId: row.id,
      },
    });
  };

  const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));

  const createSubscription = async () => {
    try {
      setModalProgressOpen(true);
      setModalProgressValue(0);
      setModalProgressMessage('Creating subscription');

      // Create subscription
      const subscriptionParameters = {
        name,
        startTime: startDate?.toISOString()?.split('T')?.[0],
        endTime: endDate?.toISOString()?.split('T')?.[0],
        bbox: bBoxPoints,
        problematicAreaPercentage: cloudCoveragePercentage / 100,
        imageCount: selectedImagery.length,
        aoi: getBBoxFromPolyCheck ? null : aoiGeojsonData,
      };
      let progress = 0;

      // console.log({ subscriptionParameters });
      const subscription = await createSubscriptionAPI(subscriptionParameters);
      // console.log({ subscription });
      progress = 20;

      setModalProgressValue(progress);

      const delta = 80 / sentinelImagery.length;

      await Bluebird.map(
        selectedImagery,
        async (row) => {
          const imagery = {
            subscriptionId: subscription?.id,
            date: row?.datetime?.split('T')?.[0],
            dataSource: row?.id,
            props: {
              SCL: row?.SCL,
              TCI: row?.TCI,
              L2A_URL: row?.TCI_TileURL,
              mgrs: row?.mgrs,
              indices: row?.indices,
            },
          };

          await createSubscriptionImageryAPI(imagery);
          progress += delta;

          setModalProgressValue(progress);
        },
        {
          concurrency: 3,
        }
      );

      setToastAlertOpts({
        show: true,
        severity: 'success',
        message: 'Subscription created successfully',
        duration: 5000,
      });

      setModalProgressOpen(false);
      // Navigate to the subscriptions page using react router
      await wait(1000);

      const encodedName = encodeURIComponent(name);

      history.push(
        `/quick-dr/subscriptions/${
          subscription.id
        }?name=${encodedName}&bbox=${bBoxPoints.join(',')}`
      );
    } catch (err) {
      setModalProgressOpen(false);
      setAlertBox({
        message: err?.response?.data?.message,
        variant: 'error',
        open: true,
      });
    }
  };
  function getPolygonArea(p) {
    // console.log('p', turf.polygon(p));

    const poly = turf.polygon(p);
    // console.log('poly', poly.geometry);
    const area = turf.area(poly.geometry);
    // Convert area from square meters to square kilometers
    const areaSqKm = turf.convertArea(area, 'meters', 'kilometers');
    // console.log('area', areaSqKm);
    return areaSqKm;
  }

  useEffect(() => {
    if (polygon && polygon[0] && polygon[0].length) {
      const maxCallback = (max, cur) =>
        Math.max(parseFloat(max), parseFloat(cur));
      const minCallback = (max, cur) =>
        Math.min(parseFloat(max), parseFloat(cur));

      const minLng = polygon[0]
        .map((point) => point[0])
        .reduce(minCallback, Infinity);
      const maxLng = polygon[0]
        .map((point) => point[0])
        .reduce(maxCallback, -Infinity);

      const minLat = polygon[0]
        .map((point) => point[1])
        .reduce(minCallback, Infinity);
      const maxLat = polygon[0]
        .map((point) => point[1])
        .reduce(maxCallback, -Infinity);

      setBBoxPoints([minLng, minLat, maxLng, maxLat]);

      const bboxAreaHec = calculateBboxSize([minLng, minLat, maxLng, maxLat]);

      setBboxSize(bboxAreaHec);
    }
  }, [polygon]);

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  };

  const copyContent = (responseJson) => {
    copyToClipboard(JSON.stringify(responseJson));
  };

  const handleClick = () => {
    resetPreviousData();

    const parsedBboxPoints = bBoxPoints.map((point) => parseFloat(point));

    const params = {
      start_date: startDate?.toISOString()?.split('T')?.[0],
      end_date: endDate?.toISOString()?.split('T')?.[0],
      bbox: parsedBboxPoints?.join(','),
    };

    const rectangle = [
      [
        [parsedBboxPoints[0], parsedBboxPoints[3]],
        [parsedBboxPoints[2], parsedBboxPoints[3]],
        [parsedBboxPoints[2], parsedBboxPoints[1]],
        [parsedBboxPoints[0], parsedBboxPoints[1]],
        [parsedBboxPoints[0], parsedBboxPoints[3]],
      ],
    ];

    if (aoiValue.current.current === 'bbox') {
      flytoLocation(null, bBoxPoints);
      draw.changeMode('simple_select');
      dispatch({
        type: setDrawMode,
        payload: {
          drawMode: 'simple_select', // Set to a non-drawing mode
        },
      });
      dispatch({
        type: setClearButton,
        payload: {
          showClearButton: true,
        },
      });

      clearMapboxPopup();

      const calculatedBboxSize = calculateBboxSize(bBoxPoints);

      setBboxSize(calculatedBboxSize);

      const bboxPolygon = turf.bboxPolygon(bBoxPoints);

      const topPoint = getPolygonInfoWindowPoint(bboxPolygon);

      new mapboxgl.Popup({
        closeOnClick: false,
        closeButton: false,
        className: 'top-popup',
        maxWidth: '150px',
      })
        .setLngLat(topPoint)
        .setHTML(
          `<p class="${
            calculatedBboxSize < MIN_AOI_AREA_IN_HECTARE ||
            calculatedBboxSize > MAX_AOI_AREA_IN_HECTARE
              ? 'warning'
              : ''
          }"><b>Area:</b> ${calculatedBboxSize.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })} ha</p>`
        )
        .addTo(map);

      if (
        calculatedBboxSize < MIN_AOI_AREA_IN_HECTARE ||
        calculatedBboxSize > MAX_AOI_AREA_IN_HECTARE
      ) {
        const bottomPoint = getPolygonInfoWindowPoint(bboxPolygon, 'bottom');

        new mapboxgl.Popup({
          closeOnClick: false,
          closeButton: false,
          anchor: 'top',
          maxWidth: '500px',
          className: 'bottom-popup',
        })
          .setLngLat(bottomPoint)
          .setHTML(
            `ⓘ Please select an AOI between ${MIN_AOI_AREA_IN_HECTARE.toLocaleString()}ha and ${MAX_AOI_AREA_IN_HECTARE.toLocaleString()}ha.`
          )
          .addTo(map);

        return;
      }
    }

    if (polygon && polygon.length) {
      // eslint-disable-next-line no-use-before-define
      if (!comparePolygonArrays(rectangle[0], polygon[0])) {
        dispatch({
          type: setPolygon,
          payload: {
            polygon: rectangle,
          },
        });
      }
    }

    setLoadingPDRData(true);
    getSentinelCover(params)
      .then((res) => {
        if (Array.isArray(res)) return res;

        setLoadingPDRData(false);

        return [];
      })
      .then((resp) => {
        setSentinelImagery(
          resp.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
        );
        setTimeout(() => {
          if (sentinelViewRef.current) {
            sentinelViewRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }, 200);
      })
      .catch((error) => {
        const { response } = error;
        setAlertBox({
          message: response?.data?.message ?? error.message,
          variant: 'error',
          open: true,
        });
        setLoadingPDRData(false);

        if (response?.data?.type === 'MGRS_TILE_EXCEPTION') {
          dispatch({
            type: setMGRSTileExceptionPolygon,
            payload: {
              polygon: response?.data?.payload,
            },
          });
        }
      });
  };

  const comparePolygonArrays = (array1, array2) =>
    array1.every(
      (value, index) =>
        value[0] === array2[index][0] && value[1] === array2[index][1]
    );

  const disableCreateButton =
    !name || availableCredits <= 0 || selectedImagery.length <= 0;

  const isSentinelCoverGenerateButtonDisable = () => {
    if (bBoxPoints.length !== 4) {
      return true;
    }

    const checkAllPoints = bBoxPoints.every(
      (point) =>
        lodash.isNumber(parseFloat(point)) && !lodash.isNaN(parseFloat(point))
    );

    if (!checkAllPoints) {
      return true;
    }

    if (
      bboxSize !== 0 &&
      (bboxSize < MIN_AOI_AREA_IN_HECTARE || bboxSize > MAX_AOI_AREA_IN_HECTARE)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h3
          className={classes.parameters}
          style={{
            marginTop: '0px',
            marginLeft: '0px',
            marginBottom: '0px',
            fontSize: '25px',
            backgroundColor: 'white',
            fontWeight: 'bolder', // Changed from 'bold' to 'bolder' for extra boldness
            fontFamily: 'Inter, sans-serif',
          }}
        >
          Create New Order
        </h3>
        <div style={{ marginTop: '30px' }} />
        <InputField
          label="Project Name"
          value={name}
          onChange={(e) => {
            const { value } = e.target;
            setName(value);
          }}
          required
        />
        <div style={{ marginTop: '30px' }} />
        <div className={classes.textFields}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disableToolbar
              inputVariant="filled"
              InputProps={{
                disableunderline: 'true',
                classes: {
                  root: classes.inputRoot,
                },
              }}
              margin="normal"
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className="mr-12"
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              disableToolbar
              inputVariant="filled"
              InputProps={{
                disableunderline: 'true',
                classes: {
                  root: classes.inputRoot,
                },
              }}
              margin="normal"
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ marginTop: '30px' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 style={{ fontSize: '14px', marginRight: '10px' }}>Select AOI:</h3>
          <RadioGroup
            row
            aria-label="aoi"
            name="aoi"
            value={aoiSelection}
            onChange={handleAoiChange}
          >
            <FormControlLabel
              value="bbox"
              control={<Radio />}
              label={<span style={{ fontSize: '14px' }}>by Bounding Box</span>}
            />
            <FormControlLabel
              value="vector"
              control={<Radio />}
              label={
                <span style={{ fontSize: '14px' }}>
                  by Vector File
                  <IconButton onClick={handleOpenModalGeojsonInstructions}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </span>
              }
            />

            <GeoJSONInstructionModal
              open={showInstructionModal}
              onClose={() => {
                setShowInstructionModal(false);
              }}
            />

            {/* <FormControlLabel
              value="vector"
              control={<Radio />}
              label={<span style={{ fontSize: '14px' }}>by Geojson File</span>}
            /> */}
          </RadioGroup>
        </div>
        <AoiChangeConfirmationModal
          open={showAoiChangeConfirmation}
          onConfirm={handleAoiChangeConfirmation}
          onCancel={handleCancelAoiChange}
        />
        {aoiSelection === 'bbox' && (
          <>
            <div className={classes.textFields}>
              <InputField
                label="bbox string in format of minLongitude, minLatitude, maxLongitude, maxLatitude"
                value={bBoxPoints.join(',')}
                onChange={(e) => {
                  const coordinates = e.target.value
                    .split(',')
                    .map((v) => v.trim());

                  const regex = /^-?(\d+(\.\d*)?)?$/;

                  if (
                    coordinates.length <= 4 &&
                    coordinates.every((coor) => regex.test(coor))
                  ) {
                    setBBoxPoints(coordinates);
                    setBboxSize(0);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <RotateIcon
                      style={{ fill: 'none', cursor: 'pointer' }}
                      onClick={() => {
                        const [coor1, coor2, coor3, coor4] = bBoxPoints;
                        if (coor1 && coor2 && coor3 && coor4) {
                          const bbox = [coor2, coor1, coor4, coor3];
                          setBBoxPoints(bbox);
                          setBboxSize(0);
                        }
                      }}
                    />
                  </InputAdornment>
                }
              />
            </div>
          </>
        )}
        {aoiSelection === 'vector' && (
          <>
            <div className={classes.textFields}>
              <div
                {...getRootProps()}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '65px', // adjust as needed
                  width: '100%',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid lightgrey',
                  borderRadius: '4px', // optional, for rounded corners
                  cursor: 'pointer',
                }}
              >
                <input {...getInputProps()} />
                <p style={{ textAlign: 'center' }}>
                  {loading ? (
                    'Loading...'
                  ) : (
                    <strong className={classes.uploadGeojson}>
                      Upload Geojson file
                    </strong>
                  )}
                </p>
              </div>
            </div>
            <div className={classes.textFields}>
              <InputField
                label="Location in format of minLongitude"
                value={bBoxPoints[0] ?? ''}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.inputRootText,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    shrink: classes.shrinkLabel,
                  },
                }}
                className="mr-12"
                variant="filled"
              />
              <InputField
                label="Location in format of minLatitude"
                value={bBoxPoints[1] ?? ''}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.inputRootText,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    shrink: classes.shrinkLabel,
                  },
                }}
                variant="filled"
              />
            </div>
            <div className={classes.textFields}>
              <InputField
                label="Location in format of maxLongitude"
                value={bBoxPoints[2] ?? ''}
                className="mr-12"
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.inputRootText,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    shrink: classes.shrinkLabel,
                  },
                }}
                variant="filled"
              />
              <InputField
                label="Location in format of maxLatitude"
                value={bBoxPoints[3] ?? ''}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.inputRootText,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    shrink: classes.shrinkLabel,
                  },
                }}
                variant="filled"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getBBoxFromPolyCheck}
                    onChange={(e) => {
                      const checkboxStatus = e.target.checked;
                      setGetBBoxFromPolyCheck(checkboxStatus);
                      if (aoiGeojsonData) {
                        if (map.getSource('bbox-area-mask')) {
                          map.removeLayer('bbox-area-mask');
                          map.removeSource('bbox-area-mask');
                        }

                        // Add a new layer to the map for the bbox
                        map.addSource('bbox-area-mask', {
                          type: 'geojson',
                          data: getBBoxFromPolyCheck
                            ? aoiGeojsonData
                            : turf.bboxPolygon(bBoxPoints),
                        });

                        map.addLayer({
                          id: 'bbox-area-mask',
                          type: 'fill',
                          source: 'bbox-area-mask',
                          layout: {},
                          paint: {
                            'fill-color': '#088', // Set the fill color
                            'fill-opacity': 0.5, // Set the fill opacity
                          },
                        });

                        mapTopPopup.current.setHTML(
                          `<p class="${
                            bboxSize < MIN_AOI_AREA_IN_HECTARE ||
                            bboxSize > MAX_AOI_AREA_IN_HECTARE
                              ? 'warning'
                              : ''
                          }"><b>Area:</b> ${
                            checkboxStatus
                              ? bboxSize.toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                })
                              : geojsonSize.toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                })
                          } ha</p>`
                        );
                      }
                    }}
                    name="bboxFromPolyCheck"
                  />
                }
                label={
                  <span style={{ fontSize: '0.875rem' }}>
                    Get result as bbox of the polygon
                  </span>
                }
              />
            </div>
            <div className={classes.textFields} />
          </>
        )}

        <CloudCoverageSlider
          cloudCoveragePercentage={cloudCoveragePercentage}
          setCloudCoveragePercentage={setCloudCoveragePercentage}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          fullWidth
          className={classes.generateResp}
          disabled={isSentinelCoverGenerateButtonDisable()}
        >
          Generate Response
        </Button>

        <Collapse in={alertBox.open}>
          <Alert
            icon={alertBox.icon}
            severity={alertBox.variant || 'warning'}
            onClose={() => {
              setAlertBox({
                open: false,
              });
            }}
            sx={{
              mt: 4,
              mb: 2,
              '&': {
                alignItems: 'center',
              },
            }}
          >
            {alertBox.message}
          </Alert>
        </Collapse>

        {availableCredits <= 0 && creditApiLoadingState === false && (
          <Alert
            severity="warning"
            sx={{
              mt: 4,
              mb: 2,
              '&': {
                alignItems: 'center',
              },
            }}
          >
            Credit not available. Please reach out to sales team to issue credit
            to your account.
          </Alert>
        )}

        <div style={{ marginTop: '30px' }} />

        <div className={classes.imageGrid}>
          {requestUrl && (
            <div>
              <img
                className={classes.drImageThumbnail}
                src={imageBlobUrl}
                alt="Available Sentinel Imageries"
              />
            </div>
          )}
        </div>
        <div>
          <SentinelAvailableImageriesTable
            sentinelImagery={sentinelImagery}
            selectedImagery={selectedImagery}
            setSentinelImagery={setSentinelImagery}
            setSelectedImagery={setSelectedImagery}
            bbox={bBoxPoints.join(',')}
            renderTCI={renderTCI}
            cloudCoveragePercentage={cloudCoveragePercentage}
            loadingPDRData={loadingPDRData}
            setLoadingPDRData={setLoadingPDRData}
            setAlertBox={setAlertBox}
            startDate={startDate}
            endDate={endDate}
          />
        </div>

        {sentinelImagery?.length && !loadingPDRData ? (
          <div>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              ref={sentinelViewRef}
              onClick={createSubscription}
              disabled={disableCreateButton}
              className={classes.mx8}
            >
              Create
            </Button>

            <div style={{ textAlign: 'center' }}>
              {!name && 'Please enter a project name'}
            </div>
          </div>
        ) : null}

        <ProgressModal
          open={modalProgressOpen}
          onClose={() => {}}
          progress={modalProgressValue}
          message={modalProgressMessage}
        />
      </div>
      <Toast
        open={toastAlertOpts.show}
        duration={toastAlertOpts.duration}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          setToastAlertOpts({ ...toastAlertOpts, show: false });
        }}
        severity={toastAlertOpts.severity}
        message={toastAlertOpts.message}
      />
    </div>
  );
};

export default PartialDRBboxDrawer;
